<template>
  <router-view />
</template>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
