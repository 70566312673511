<template>
  <div class="nav">
    <div class="tab-container">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="首页" name="home"></el-tab-pane>
        <el-tab-pane label="医馆简介" name="医馆简介"></el-tab-pane>
        <el-tab-pane label="新闻动态" name="新闻动态"></el-tab-pane>
        <el-tab-pane label="专家团队" name="专家团队"></el-tab-pane>
        <el-tab-pane label="特色专科" name="特色专科"></el-tab-pane>
        <el-tab-pane label="非物质文化遗产-胡氏儿科" name="非物质文化遗产"></el-tab-pane>
        <el-tab-pane label="特色项目" name="特色项目"></el-tab-pane>
        <el-tab-pane label="百安精选" name="百安精选"></el-tab-pane>
        <el-tab-pane label="便民服务" name="便民服务"></el-tab-pane>
        <el-tab-pane label="名医讲堂" name="名医讲堂"></el-tab-pane>
        <el-tab-pane label="党建工作" name="党建工作"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
let activeName = ref("first");
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  background-color: #00b2b3;
  .tab-container {
    width: 1400px;
    height: 100%;
    margin: 0 auto;
    :deep(.el-tabs__content) {
      display: none;
    }
    :deep(.el-tabs__header) {
      margin: 0;
    }
    :deep(.el-tabs__item) {
      height: 60px;
      color: white;
      padding: 0 20px !important;
      font-size: 18px;
      font-weight: bold;
    }
    :deep(.el-tabs__nav-wrap:after) {
      background: transparent;
    }
    :deep(.el-tabs__item.is-active, .el-tabs__item:hover) {
      color: #00b2b3 !important;
      background-color: white !important;
    }
    :deep(.el-tabs__active-bar) {
      background: #00b2b3;
    }
    :deep(.el-tabs__nav-next) {
      line-height: 60px;
      color: white;
    }
    :deep(.el-tabs__nav-prev) {
      line-height: 60px;
      color: white;
    }
  }
}
</style>