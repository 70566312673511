<template>
  <div class="header-container">
    <div class="logo"></div>

    <div class="company-message">
        <div class="tel"></div>
        <div class="code">

        </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss">
.header-container {
    height: 100px;
    position: relative;
    min-width: 1200px;
    .logo {
        position: absolute;
        width: 435px;
        height: 90px;
        top: 16px;
        left: 180px;
        background: url('@/assets/home/logo.png') no-repeat;
    }

    .company-message {
        position: absolute;
        top: 10px;
        right: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        .tel {
            width: 173px;
            height: 41px;
            background: url('@/assets/home/tel.png') no-repeat;
        }
        .code {
            width: 189px;
            height: 93px;
            margin-left: 20px;
            background: url('@/assets/home/code.png') no-repeat;
        }
    }
}
</style>