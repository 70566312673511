import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: HomeView,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: "home",
        component: () => import('@/views/home/index.vue')
      }
    ],

  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
